// ? Border Global Styling

.border {
  border: 1px solid $border_color !important;
}
.borderTop {
  border-top: 1px solid $border_color !important;
}
.borderBottom {
  border-bottom: 1px solid $border_color !important;
}
.borderLeft {
  border-left: 1px solid $border_color !important;
}
.borderRight {
  border-right: 1px solid $border_color !important;
}

// ? Border Radius Range

.borderRadius0 {
  border-radius: 0px !important;
}
.borderRadius5 {
  border-radius: 5px !important;
}
.borderRadius6 {
  border-radius: 6px !important;
}
.borderRadius10 {
  border-radius: 10px !important;
}
.borderRadius15 {
  border-radius: 15px !important;
}
.borderRadius20 {
  border-radius: 20px !important;
}
.borderRadius25 {
  border-radius: 25px !important;
}

// ? Border None on Sides

.borderNone {
  border: none !important;
}
.borderTopNone {
  border-top: none !important;
}
.borderBottomNone {
  border-bottom: none !important;
}
.borderLeftNone {
  border-left: none !important;
}
.borderRightNone {
  border-right: none !important;
}
