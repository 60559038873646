.WishlistDropdown {
  position: relative;

  .WishlistDropdownContent {
    display: block;
    padding: 10px;
    background: white;
    top: 50px !important;
    right: 130px !important;
    width: 491px !important;
    position: fixed !important;
    max-width: 491px !important;

    z-index: 10;
    // border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    .WishlistItems {
      height: auto !important;
      max-height: 320px !important;
      overflow-y: scroll !important;

      .priceCut {
        color: #606060;
        letter-spacing: 0px;
        font-size: 16px !important;
        text-decoration: line-through;
        font-family: "Inter-Light" !important;
        font-weight: 100 !important;
      }

      .discountTag {
        height: 23px;
        color: #ea462f;
        text-align: center;
        letter-spacing: 0px;
        font-size: 16px !important;
        font-family: 'Inter-Regular' !important;
        font-weight: 100;
        background: #ffeff1 0% 0% no-repeat padding-box;
      }
    }
  }
}

/* Full-page overlay */
.overlay {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  display: none; /* Initially hidden */
  z-index: 2; /* Place the overlay above other content */

  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.7;
  //   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

/* Blur effect on the rest of the page when the dropdown is open */
.dropdown-opened .overlay {
  display: block; /* Show the overlay when the dropdown is open */
  backdrop-filter: blur(4px); /* Add blur effect to the overlay */
  z-index: 2;
}
.no-scroll {
  overflow: hidden !important;
  height: 100%;
}