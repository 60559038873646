.signup-form{
    .ant-form{
        padding:0px 54.62px 0px 55.84px !important;
    }
    .form-label{
        font-size: 16px;
        line-height: 25px; 
    }
    .signup-btn{
        width: 100%;
        height: 44px;
        font-size: 16px;
        font-weight: 600px !important;
        border-radius: 6px;
        color:white;
        background-color: #ff0d50;
        span{
            color:white !important;
        }
    }
}

.signup-form-mobile{
    .ant-form{
        padding:0px 20px 0px 20px !important;
    }
    .form-label{
        font-size: 16px;
        line-height: 25px; 
    }
    .signup-btn{
        width: 100%;
        height: 44px;
        font-size: 16px;
        font-weight: 600px !important;
        border-radius: 6px;
        color:white;
        background-color: #ff0d50;
        span{
            color:white !important;
        }
    }
}

body .sign-up-captcha-div{
    padding:0px 0px 20px 0px;
}

body .sign-up-captcha-error{
    color: red;
    font-size: 11px;
}