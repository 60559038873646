@import "../../../../../../styles/Colors.scss";

.brands-alphabet-search-container {
  background-color: #f2f3f7 !important;
  padding: 15px 10px 0px 10px;
  height: 440px;
  .brands-search-component {
    padding: 0 !important;
    .brands-search-bar {
      padding: 10px 0px 0px 0px;

      span.ant-input-group-addon {
        background-color: #ffffff;
        button.ant-input-search-button {
          border: none !important;
          height: 30px !important;
        }
      }
      .ant-input-suffix {
        padding-top: 4px;
      }

      ::placeholder {
        color: $black;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $black;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $black;
      }

      svg {
        color: $black;
      }
    }
    .ant-select-item {
      .ant-select-item-option {
        font-size: 10px !important;
      }
    }

    .brands-with-letters {
      background-color: #ffffff !important;
      margin: 10px 0px;
      .brands-vs-letter {
        max-height: 320px;
        overflow: scroll;
        padding: 5px 0px 0px 10px;
        scroll-behavior: smooth;
        p {
          font-family: "Inter-bold" !important;
          margin: 0px;
          font-size: 12px;
          font-weight: 100 !important;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          //width: 98%;
          li {
            //display: inline-block;
            //margin: 0 10px;
            font-size: 12px;
            line-height: 16px;

            cursor: pointer;
          }
          border-bottom: 1px solid #e0e0e0;
        }
        & :last-child {
          padding: 0px 0px 10px 0px;
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .alphabet-list {
      ul {
        list-style: none;
        padding: 5px 0px;
        margin: 0px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        li {
          //display: inline-block;
          //margin: 0 10px;
          font-size: 9px;
          line-height: 12px;
          cursor: pointer;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .brand-imgs-section {
    padding: 0px !important;
    .brand-imgs-container {
      background: #ffffff;
      // border: "4px solid #ffffff",
      margin: 8px 3px;
      padding: 20px 10px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-typography {
    margin: 0px !important;
    font-family: 'Inter-Medium' !important;
    font-weight: 100;
  }
}

// autocomplete results after searching
.ant-select-dropdown {
  border-radius: 0px !important;

  .ant-select-item-option-content {
    font-size: 12px !important;
  }
}
.brands-logo-inner {
  display: flex;
  flex-wrap: wrap;
  max-height: 416px;
  overflow-y: scroll;
}
.brands-logo-inner > a {
  background: #fff;
  margin: 8px 3px;
  padding: 20px 10px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(12% - 12px);
  margin-right: 12px;
}
.brands-logo-inner > a img {
  width: 100%;
  height: 100%;
}
.alphabet-list .active {
  font-weight: 800 !important;
  font-size: 12px !important;
  color: black !important;
}
.show-more {
  display: flex;
  justify-content: center;
  flex: 0 0 100%;
}
.show-more span {
  padding: 4px 10px;
  border: 1px solid #ed2690;
  width: max-content;
  color: #ed2690;
  cursor: pointer;
}
