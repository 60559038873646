@import "../../../../styles/Colors.scss";

.MegaMenuContainer {
  background-color: $background;
  // position: absolute;
  // padding: 20px 50px;
  // //height: 60vh;
  // width: 100%;
  // z-index: 1;
  // top: 0;
  // height: 440px;
  height: auto;
  padding: 10px 20px;
  // min-width: 1250px;
  overflow: auto;
  .ant-list {
    min-width: 100%;
    .ant-spin-nested-loading {
      .ant-spin-container {
        ul {
          // display: flex;
          // flex-wrap: wrap;
          // align-items: start;
          display: flex;
          flex-wrap: wrap;
          align-items: start;
          flex-direction: column;
          /* min-width: 100%; */
          max-height: 440px;
          li {
            padding: 0px;

            h5 {
              font-size: 14px;
              font-family: 'Inter-Medium'!important;
              margin: 5px 0px !important;
              font-weight: 100;
            }
            span {
              text-align: left;
              color: $secondaryOne;
              font-family: 'Inter-Regular'!important;
            }
          }
        }
      }
    }
  }

  .ant-space {
    gap: "2px" !important;
  }

  // .ant-space-align-center {
  //   align-items: start !important;
  // }
  // .ant-list-items {
  //   align-items: start;
  //   .ant-list-item {
  //     align-items: start;
  //     .ant-space-align-center {
  //       align-items: start !important;
  //       gap: 0px !important;
  //     }
  //   }
  // }
}
