/*  Checkboxes Global Styling */

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: $primary_red !important;
  background-color: $primary_red !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: $primary_red !important;
}

.ant-checkbox-wrapper span:nth-of-type(even) {
  width: 100% !important;
}

.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
.ant-checkbox-checked:hover .ant-checkbox-inner {
  border: 1px solid $primary_red !important;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-checkbox-checked:after {
  border: none !important;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-acm2ia).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: $primary_red !important;
}

.checkboxChecked {
  padding: 8px !important;
  border-radius: 6px !important;
  border: 1px solid $primary_red !important;
}

.checkboxBorder {
  padding: 8px !important;
  border-radius: 6px !important;
  border: 1px solid $border !important;
}

// .ant-checkbox :hover {
//   color: $primary_red !important;
// }

// .ant-checkbox-wrapper {
//   span:nth-of-type(2) {
//     width: 100% !important;
//   }
// }

// .ant-checkbox-wrapper > span:nth-child(2) {
//   width: 100% !important;
// }
