.sale-container {
  // for third collections set
  // .ant-card {
  //   // height: 0px !important;
  // }
  // .ant-card-body {
  //   background: transparent !important;
  //   position: relative;
  //   top: -30px;
  //   height: 0px !important;
  //   padding: 0px;
  //   text-align: center;
  // }
  height: 440px;
  padding: 10px;
  h5 {
    margin: 10px 0px 0px 0px;
    text-align: left;
    font-size: 14;
    font-family: "Inter-Medium" !important;
    font-weight: 100;

  }
  .ant-card {
    border-radius: 0px;
    .ant-card-cover {
      img {
        border-radius: 0px;
      }
    }
    .ant-card-body {
      padding: 5px;
      .ant-card-meta-title {
        font-size: 12px;
        font-family: "Inter-bold" !important;
        font-weight: 100 !important;
      }
    }
  }

  .right-collections-cards {
    .ant-card-body {
      display: none;
    }
  }
}

// Mz Css
body .sale-container-right {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  max-width: 50%;
}
body .sale-container-left {
  flex: 0 0 50%;
  max-width: 50%;
}

body .sale-container-right-grid {
  column-gap: 7px;
}
body .sale-container-right-grid > div {
  width: calc(25% - 7px);
}
body .sale-container-right-grid > div > div {
  max-width: 100%;
}
body .sale-container-right-grid > div .ant-image-img {
  // height: 160px;
  // vertical-align: unset;
  // max-width: 140px;
  height: 199px;
  object-fit: cover;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  body .sale-container-right-grid > div .ant-image-img {
    height: 183px;
    object-fit: cover;
  }
}