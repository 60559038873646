@import "../../../../../styles/Colors.scss";

.side-nav-drawer {
  z-index: 2;
  .ant-drawer-body {
    padding: 0px !important;
    padding-bottom: 20px!important;
  }
  .ant-drawer-header {
    // display: none !important;x
    display: revert;
    padding: 0px !important;
    border: none;
    .ant-drawer-header-title {
      background-color: $surface;
      border-radius: 50%;
      position: absolute;
      right: -30px;
      top: 10px;

      button {
        margin: 0px;
        // padding: 2px 4px;
        padding: 3px 2px;
        height: 21px;
        width: 22px;
        margin-bottom: 2px;
        svg {
          font-size: 16px;
        }
      }
    }
  }

  .ant-drawer-body {
    color: #000000;

    // .mobile-side-menu-cat-container {
    //   height: 120px;
    // }
    h3 {
      font-family: 'Poppins-Medium' !important;
      margin-bottom: 0px 0px 2px 0px;
    }
    .sub-cats-desc {
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;

      .description {
      }
    }
  }

  .ant-menu {
    .ant-menu-title-content {
      // font-weight: 400;
      // font-size: 12px;
    }
    li,
    div {
      margin: 0px !important;
      border-radius: 0px;

      border: 1px solid $surface;
      border-bottom: 1px solid #f0f0f0;
    }
  }

  .side-menu-container {
    height: 120px;

    .side-menu {
      .arrows {
        border-radius: 50%;
        background-color: $surface;
        width: 20px;
        height: 20px;
        svg {
          font-size: 18px;
        }
      }
    }
  }

  .side-nav-brands-search {
    background-color: $surface;

    input,
    button {
      border-radius: 0px;
      border: none;
    }

    .search-results {
    }
  }
  .borderBottom {
    border-bottom: 1px solid #8c8c8c;
  }

  .ant-drawer-content-wrapper {
    width: 360px !important;
  }
}

.mobile-side-nav-drawer {
  .ant-drawer-header {
    // display: none !important;x
    display: block;
    padding: 10px;
    border: none;
    .ant-drawer-header-title {
      background-color: $surface;
      border-radius: 50%;
      position: unset;
      text-align: center;
      //right: -30px;
      // top: 10px;
      height: 48px;
     

      .ant-drawer-title {
        font-size: 18px;
        font-weight: 100 !important;
        margin-right: 20px;
        
      }
      button {
        display: flex;
        align-items: center;
        width: unset;
        svg {
          font-size: 24px;
          color: $genral;
        }
      }
    }
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
