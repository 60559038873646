/* Default Buttons Global Styling */

.primary_btn {
  height: auto;
  border: none !important;
  // text-transform: uppercase !important;

  border-radius: 31px;
  background: $primary_red;
  border: 2px solid $primaryWhite;

  gap: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.primary_btn_outline {
  height: auto;
  // text-transform: uppercase !important;

  border-radius: 31px;
  color: $primary_red !important;
  background: $primaryWhite !important;
  border: 1px solid $primary_red !important;

  gap: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn_disabled {
  opacity: 1 !important;
  height: auto;
  border: none !important;
  color: $white !important;
  border-radius: 4px !important;
  // text-transform: uppercase !important;
  background: $disabled 0% 0% no-repeat padding-box !important;

  gap: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn_fixed {
  left: 10px;
  right: 10px;
  bottom: 10px;
  position: fixed !important;
}
