.LoginDropdown {
  .LoginDropdownContent {
    z-index: 10;
    background: white;
    top: 50px !important;
    right: 40px !important;
    width: 290px !important;
    max-width: 300px !important;
    position: fixed !important;
    padding: 35px 30px 20px 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

/* Full-page overlay */
.overlay {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  display: none; /* Initially hidden */
  z-index: 2; /* Place the overlay above other content */

  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.7;
  //   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

/* Blur effect on the rest of the page when the dropdown is open */
.dropdown-opened .overlay {
  display: block; /* Show the overlay when the dropdown is open */
  backdrop-filter: blur(4px); /* Add blur effect to the overlay */
  z-index: 2;
}

.no-scroll {
  overflow: hidden !important;
  height: 100%;
}

// Mz css
.LoginDropdown .LoginDropdownContent {
  right: 0!important;
}