// Margin Range------------>

.marginAuto {
  margin: auto !important;
}
.margin {
  margin: 25px auto;
}
.margin0 {
  margin: 0 !important;
}
.margin5 {
  margin: 5 !important;
}
.margin10 {
  margin: 10px !important;
}
.margin15 {
  margin: 15px !important;
}
.margin20 {
  margin: 20px !important;
}
.margin25 {
  margin: 25px !important;
}
.margin30 {
  margin: 30px !important;
}
.margin35 {
  margin: 35px !important;
}
.margin40 {
  margin: 40px !important;
}
.margin45 {
  margin: 45px !important;
}
.margin50 {
  margin: 50px !important;
}

// Margin Top Range------------>

.marginTop0 {
  margin-top: 0px !important;
}
.marginTop5 {
  margin-top: 5px !important;
}
.marginTop10 {
  margin-top: 10px !important;
}
.marginTop15 {
  margin-top: 15px !important;
}
.marginTop20 {
  margin-top: 20px !important;
}
.marginTop25 {
  margin-top: 25px !important;
}
.marginTop30 {
  margin-top: 30px !important;
}
.marginTop35 {
  margin-top: 35px !important;
}
.marginTop40 {
  margin-top: 40px !important;
}
.marginTop45 {
  margin-top: 45px !important;
}
.marginTop50 {
  margin-top: 50px !important;
}
.marginTop60 {
  margin-top: 60px !important;
}
.marginTop65 {
  margin-top: 65px !important;
}

// Margin Bottom Range------------>

.marginBottom0 {
  margin-bottom: 0px !important;
}
.marginBottom5 {
  margin-bottom: 5px !important;
}
.marginBottom10 {
  margin-bottom: 10px !important;
}
.marginBottom15 {
  margin-bottom: 15px !important;
}
.marginBottom20 {
  margin-bottom: 20px !important;
}
.marginBottom25 {
  margin-bottom: 25px !important;
}
.marginBottom30 {
  margin-bottom: 30px !important;
}
.marginBottom35 {
  margin-bottom: 35px !important;
}
.marginBottom40 {
  margin-bottom: 40px !important;
}
.marginBottom45 {
  margin-bottom: 45px !important;
}
.marginBottom50 {
  margin-bottom: 50px !important;
}

// Margin Left Range------------>

.marginLeft0 {
  margin-left: 0px !important;
}
.marginLeft5 {
  margin-left: 5px !important;
}
.marginLeft10 {
  margin-left: 10px !important;
}
.marginLeft15 {
  margin-left: 15px !important;
}
.marginLeft20 {
  margin-left: 20px !important;
}
.marginLeft25 {
  margin-left: 25px !important;
}
.marginLeft30 {
  margin-left: 30px !important;
}
.marginLeft35 {
  margin-left: 35px !important;
}
.marginLeft40 {
  margin-left: 40px !important;
}
.marginLeft45 {
  margin-left: 45px !important;
}
.marginLeft50 {
  margin-left: 50px !important;
}

// Margin Right Range------------>

.marginRight0 {
  margin-right: 0px !important;
}
.marginRight5 {
  margin-right: 5px !important;
}
.marginRight10 {
  margin-right: 10px !important;
}
.marginRight15 {
  margin-right: 15px !important;
}
.marginRight20 {
  margin-right: 20px !important;
}
.marginRight25 {
  margin-right: 25px !important;
}
.marginRight30 {
  margin-right: 30px !important;
}
.marginRight35 {
  margin-right: 35px !important;
}
.marginRight40 {
  margin-right: 40px !important;
}
.marginRight45 {
  margin-right: 45px !important;
}
.marginRight50 {
  margin-right: 50px !important;
}
