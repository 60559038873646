.latest-cataloguess-container {
  h5 {
    text-align: left;
    font-size: 14;
    font-family: 'Poppins-Medium' !important;
  }
  .ant-card-body {
    display: none;
  }
}
.link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  opacity: 1;


  /* Define styles for the Image component */
  .catalogue-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Define styles for the Explore Catalog div */
  .explore-catalog {
    background-color: #E5E5E5;
    color: black;
    text-align: center;
    font-size: 12px;
    width: 100%;
    padding: 2px 0;
    opacity: 0.6;
    font-family: 'Inter-Regular';
    font-weight: 100;
  }
}