@import "../../../../styles/Colors.scss";
.v-hidden {
  display: none !important;
}
.NavigationContainer {
  background: $SanaPrimary_color;
  padding: 0px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 2;
  .navLink {
    height: 50px;
    .ant-typography {
      color: $secondaryOne;
      text-transform: uppercase;
      font-family: "Inter-Medium" !important;
    }
  }
  // .navLink:hover {
  //   text-decoration: none;
  //   border-bottom: 4px solid red;
  // }

  .ant-typography {
    color: $black;
  }

  .searchBar-container {
    ::placeholder {
      color: $black;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $black;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $black;
    }

    svg {
      color: $black;
    }

    input,
    button {
      height: 38px !important;
    }
  }

  .icons {
    gap: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 5px;

    .icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      cursor: pointer;
      svg {
        font-size: 18px !important;
        color: $secondaryOne;
      }
      .name {
        font-size: 12px;
        color: $secondaryOne;
        font-family: "Inter-Medium" !important;
      }
      .cart-quantity-1digit {
        position: absolute;
        top: 10px;
        right: 50px;
        color: $primaryOne;
        font-size: 8px;
        font-weight: 700;
      }
      .cart-quantity-2digit {
        position: absolute;
        top: 11px;
        right: 47px;
        color: $primaryOne;
        font-size: 6px;
        font-weight: 700;
      }
    }

    .icon-container-active {
      border-bottom: 1px solid $primaryOne;
      .name {
        color: $primaryOne;
      }
      svg {
        color: $primaryOne;
      }
    }
  }

  li.ant-list-item {
    width: 100px !important;
  }

  .ant-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    .ant-modal-title {
      font-weight: 700;
    }
  }
  .ant-modal-content {
    button.ant-modal-close {
      left: 0px !important;
      .ant-modal-close-x {
      }
    }
  }
}

.dropContainer-title {
  font-size: 14px !important;
  font-weight: 800 !important;
  margin: 0px !important;
}

.wislist-dropDown,
.cart-dropDown,
.profile-dropDown {
  position: fixed !important;
  z-index: 2 !important;
  margin-top: 20px;
  max-width: 420px;
  width: auto !important;

  // .ant-dropdown-menu {
  //   padding: 20px 5px 5px 5px !important;
  // }

  .price {
    font-weight: bold;
    font-size: 18px !important;
    color: $secondaryTwo !important;
  }

  .priceCut {
    text-decoration: line-through;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    color: #606060;
  }

  .discountTag {
    height: 23px;
    color: #ea462f;
    text-align: center;
    letter-spacing: 0px;
    font: normal normal normal 16px/25px Poppins;
    background: #ffeff1 0% 0% no-repeat padding-box;
  }
}
.ant-dropdown-menu-item:active,
.ant-dropdown-menu-item-active {
  background-color: #ffffff !important;
}

.items-dropContainer {
  width: 420px !important;
  //min-height: 160px;

  // display: flex;
  // justify-content: center;
  // align-items: center;
  h1 {
    margin: 0px;
  }
  .item {
    display: flex;
    //justify-content: center;
    align-items: start;
    flex-direction: row;
    border-bottom: 1px solid #f2f3f7;
    padding: 10px 0px;
    // .item-image {
    //   img {
    //     width: 40%;
    //   }
    // }
    .item-detail {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      width: 100%;
      .item-detail-middle {
        display: flex;
        justify-content: space-between;
        // width: 360px;
        p {
          font-size: 10px;
          text-align: left;
          width: 33.3%;
        }
      }
    }

    p,
    span {
      font-size: 11px;
      font-weight: 500;
      margin: 3px 0px;
    }

    .wishlist-item-detail {
      //padding-top: 0px;
      padding-top: 20px;
      p {
        margin: 4px 0px;
      }
    }

    .item-bold-text {
      // font-weight: 700;
      font-family: "Inter-Regular" !important;
    }
    .price {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .proceed-checkout-btn {
    background: $primaryOne !important;
    color: white !important;
    font-weight: 500;
    border-radius: 5px;
  }

  .view-btn {
    border-color: $primaryOne !important;
    color: $primaryOne !important;
    font-weight: 500;
    border-radius: 5px;
  }
  .ant-btn {
    margin-bottom: 5px;
  }

  .empty {
    text-align: center;
    font-weight: 500;
    color: $primaryOne !important;
  }
}

.currency-radioGroup {
  .radio-item {
    width: 220px;
  }
  .currency-name {
    font-size: 14px;
    font-weight: 500;
    color: #1e1e1e;
  }
  .ant-radio-wrapper-checked {
    border: none !important;
  }
}

.scroll-items {
  min-height: 160px;
  max-height: 340px;
  overflow-x: scroll;
}
.overlay {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  /* Initially hidden */
  z-index: 2;
  /* Place the overlay above other content */

  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.7;
  //   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

/* Blur effect on the rest of the page when the dropdown is open */
.dropdown-opened .overlay {
  display: block;
  /* Show the overlay when the dropdown is open */
  backdrop-filter: blur(4px);
  /* Add blur effect to the overlay */
  z-index: 2;
}

body:not(.search-opened) .header .NavigationContainer {
  max-width: 1366px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 10px 0px 0px;
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .NavigationContainer {
    padding: 0px 10px !important;
    .icons {
      gap: 10px !important;
    }
  }
  .header-navigation {
    justify-content: center !important;
  }
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.no-scroll {
  overflow: hidden !important;
  height: 100%;
}

@keyframes dotPulse {
  0% {
    transform: scale(0.8);
    opacity: 0.3;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.8);
    opacity: 0.3;
  }
}

.loading-dots {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 8px;
}

.loading-dots div {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #333;
  /* Color of the dots */
  border-radius: 50%;
  animation: dotPulse 1.4s infinite;
}

.loading-dots div:nth-child(1) {
  left: 0;
  animation-delay: -0.32s;
}

.loading-dots div:nth-child(2) {
  left: 8px;
  animation-delay: -0.16s;
}

.loading-dots div:nth-child(3) {
  left: 16px;
}

// Mz Css
.header {
  height: 50px;
  background-color: #fff;
}
// .header > div {
//   height: inherit;
//   margin: 0;
// }
body .MobileSearch {
  z-index: 2;
  min-height: 100vh;
}
body
  .brands-alphabet-search-container
  .brands-search-component
  .brands-with-letters
  .brands-vs-letter
  :last-child {
  padding: 5px 0px 5px 0px;
}
body .search-bar .searchInput {
  padding: 0px 10px;
}
body .brands-vs-letter a {
  display: flex;
  width: 100%;
  padding: 0 !important;
}
body .latest-cataloguess-container h5 {
  font-family: "Poppins-Medium" !important;
  font-weight: 100;
}
@media (min-width: 768px) {
  body .header-inner {
    background: #ffffff;
    padding: 0px 40px;
    height: 50px;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
}
