.login-form{

    .login-button-spinner svg{
        color: white;
    }

    .ant-form{
        padding:0px 54.62px 0px 55.84px !important;
    }
    .form-label{
        font-size: 16px;
        line-height: 25px;
    }
    .login-btn{
        width: 100%;
        height: 44px;
        font-size: 16px;
        font-weight: 600px !important;
        border-radius: 6px;
        color:white;
        background-color: #ff0d50;
        span{
            color:white !important;
        }
    }
    // .ant-btn-primary:hover{
    //     background-color:#ff0d50 !important; 

    // }
}