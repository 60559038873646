.MegaSearch {
}

.MegaSearchModal {
  top: 0px !important;
  height: 95vh !important;
  max-height: 95vh !important;
  margin: auto !important;
  width: 100vw !important;
  // position: fixed !important;
  // height: calc(100vh - 50px) !important;
  .ant-modal {
    left: 10px !important;
    right: 10px !important;
    top: 10px !important;
    bottom: 10px !important;
    position: fixed !important;
    // height: 90vh !important;
    width: calc(100vw - 20px) !important;
    // height: calc(100vh - 50px) !important;
    .ant-modal-content {
      padding: 0px !important;
      // width: 100vw !important;
      // height: 100vh !important;
      border-radius: 0px !important;
      //   width: calc(100vw - 20px) !important;
      //   height: calc(100vw - 10px) !important;

      .ant-modal-close-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px !important;
        background: black !important;

        svg {
          color: white !important;
        }
      }
      .ant-modal-header {
        .ant-modal-title {
        }
      }
      .ant-modal-body {
        .searchProducts {
          height: 95vh !important;
          max-height: 95vh !important;
          overflow-y: scroll !important;
        }
      }
      .ant-modal-footer {
      }
    }
  }
}

.MegaSearchCustom {
  z-index: 10;
  left: 10px !important;
  right: 10px !important;
  bottom: 10px !important;
  top: 60px !important;
  // height: 95vh !important;
  margin: auto !important;
  position: fixed !important;
  max-height: 95vh !important;
  background-color: #ffffff;
  width: calc(100vw - 20px) !important;
  height: calc(100vh - 65px) !important;
  // overflow-y: scroll;

  .closeIcon {
    svg {
      color: white !important;
    }
  }

  .ExclamationCircle {
    svg {
      font-size: 21px !important;
      color: #dc3545 !important;
    }
  }

  .productsListing {
    overflow-y: scroll;
    height: calc(100vh - 140px) !important;
  }

  .notFoundSpace > div:first-child {
    width: 100% !important;
  }

  .product_card {
    .ant-card-body {
      .ant-card-meta {
        .ant-card-meta-detail {
          .ant-card-meta-title {
            span {
              // height: auto !important;
              height: 40px !important;
              font-size: 10px !important;
              color: #434343 !important;
              line-height: 18px !important;
              letter-spacing: 0.6px !important;
            }
          }
          .ant-card-meta-description {
            span {
              font-size: 14px !important;
            }
            .discount_tag {
              span {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }
  }
}

/* Full-page overlay */
.overlay {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2; /* Place the overlay above other content */
  display: none; /* Initially hidden */
  position: fixed;

  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.7;
  //   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

/* Blur effect on the rest of the page when the dropdown is open */
.search-opened .overlay {
  display: block; /* Show the overlay when the dropdown is open */
  backdrop-filter: blur(4px); /* Add blur effect to the overlay */
  z-index: 2;
}

.mega-search-categories-link:hover{
  color: #ff0d50;
}

body .spinner-height{
  height: 300px;
}

body .spinner-flex-justify-content-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.mega-search-categories-link-div:hover > div > div:nth-child(2) span {
  color: #ff3f6c;
}

.ant-spin.ant-spin-spinning svg{
  color:#ff3f6c;
}

.scroll::-webkit-scrollbar {
  width: 8px;
  // background-color: #ff0d50;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
  border-radius: 8px;
  // background-color: #ff0d50;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #fd6a8c;
  // -webkit-box-shadow: inset 0 0 6px #ff0d50; 
  // background-color: #ff0d50;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #ff3f6c;
}

body .search-load-more-button{
  color: #ff3f6c;
  background: white;
  border: 1px solid #ff3f6c;
  box-shadow: none;
}

body .search-load-more-button:hover{
  color: white !important;
  background: #ff3f6c !important;
  border: 1px solid white !important;
  box-shadow: none !important;
}

body .mega-search-menu-brand-flex-list{
  display: flex;
  overflow-x: auto;
}

body .mega-search-menu-brand-flex-list img{
  text-wrap: nowrap;
}

.MegaSearchCustom .align-self-center{
  align-self: center;
}