// .cartDropdown {
//   top: 45px !important;
//   width: 491px !important;
//   position: fixed !important;
//   max-width: 491px !important;
//   max-height: 640px !important;
//   overflow-y: scroll !important;

//   ul {
//     padding: 10px !important;
//     border-radius: 0px !important;

//     li {
//       padding: 0 20px !important;
//     }
//     li:first-child {
//       padding: 0px !important;
//       margin-bottom: 15px !important;
//     }
//   }

//   .priceCut {
//     color: #606060;
//     letter-spacing: 0px;
//     font-size: 16px !important;
//     text-decoration: line-through;
// font-family: "Inter-Light" !important;
  // font-weight: 100 !important;
//   }

//   .discountTag {
//     height: 23px;
//     color: #ea462f;
//     text-align: center;
//     letter-spacing: 0px;
//     font-size: 16px !important;
// font-family: 'Inter-Regular' !important;
  // font-weight: 100;
//     background: #ffeff1 0% 0% no-repeat padding-box;
//   }
// }

.CartDropdown {
  position: relative;

  .CartDropdownContent {
    display: block;
    padding: 10px;
    background: white;
    top: 50px !important;
    right: 40px !important;
    width: 491px !important;
    position: fixed !important;
    max-width: 491px !important;

    z-index: 99;
    // border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    .CartItems {
      height: auto !important;
      max-height: 460px !important;
      overflow-y: scroll !important;

      .priceCut {
        color: #606060;
        letter-spacing: 0px;
        font-size: 16px !important;
        text-decoration: line-through;
        font-family: "Inter-Light" !important;
          font-weight: 100 !important;
      }

      .discountTag {
        height: 23px;
        color: #ea462f;
        text-align: center;
        letter-spacing: 0px;
        font-size: 16px !important;
        font-family: 'Inter-Regular' !important;
        font-weight: 100;
        background: #ffeff1 0% 0% no-repeat padding-box;
      }
    }
  }
}

/* Full-page overlay */
.overlay {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  display: none; /* Initially hidden */
  z-index: 2; /* Place the overlay above other content */

  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.7;
  //   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

/* Blur effect on the rest of the page when the dropdown is open */
.dropdown-opened .overlay {
  display: block; /* Show the overlay when the dropdown is open */
  backdrop-filter: blur(4px); /* Add blur effect to the overlay */
  z-index: 2;
}
.no-scroll {
  overflow: hidden !important;
  height: 100%;
}