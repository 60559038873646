@font-face {
  font-family: "Poppins-Black";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-Black.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-BlackItalic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-BlackItalic.ttf) format("opentype");
}
@font-face {
  font-family: 'Poppins-Medium';
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-BoldItalic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-BoldItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-ExtraBold.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraLight";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-ExtraLight.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-ExtraLightItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-Italic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-Italic.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-LightItalic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-LightItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-Medium.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-MediumItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-SemiBold.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-SemiBoldItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-Thin";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-Thin.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-ThinItalic";
  src: local("Poppins"),
    url(../Fonts/Poppins/Poppins-ThinItalic.ttf) format("opentype");
}
