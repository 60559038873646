@import "./Lato.scss";
@import "./Futura.scss";
@import "./Poppins.scss";
@import "./Inter.scss";

// FontSizes ------------->

.font8 {
  font-size: 8px !important;
}
.font8Bold {
  font-size: 8px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.font10 {
  font-size: 10px !important;
}
.font10Bold {
  font-size: 10px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.font11 {
  font-size: 11px !important;
}
.font11Bold {
  font-size: 11px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.font12 {
  font-size: 12px !important;
}
.uppercase{
  text-transform: uppercase !important;
}
.font12Bold {
  font-size: 12px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 600 !important;
}
.font12Light {
  font-size: 12px !important;
  font-family: "Inter-Regular" !important;
  font-weight: 100 !important;
  }
.font13 {
    font-size: 11px !important;
  }

  .font13Light {
    font-size: 11px !important;
    font-family: "Inter-Light" !important;
    font-weight: 100 !important;
  }

  .font13Bold {
    font-size: 11px !important;
    font-family: "Inter-Medium" !important;
    font-weight: 100 !important;
  }

.font14 {
  font-size: 14px !important;
}
.font14Light {
  font-size: 14px !important;
  font-family: "Inter-Light" !important;
  font-weight: 100 !important;
}
.font14Bold {
  font-size: 14px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.font15 {
  font-size: 13px !important;
}

.font15Light {
  font-size: 13px !important;
  font-family: "Inter-Light" !important;
  font-weight: 100 !important;
}

.font15Bold {
  font-size: 13px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}

.font16 {
  font-size: 16px !important;
  font-family: "Inter-Regular" !important;
  font-weight: 100 !important;
}
.font16Light {
  font-size: 16px !important;
  font-family: "Inter-Light" !important;  
  font-weight: 100 !important;
}
.font16Bold {
  font-size: 16px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.font18 {
  font-size: 18px !important;
}
.font18Bold {
  font-size: 18px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.font14 {
  font-size: 34x !important;
}
.font20 {
  font-size: 20px !important;
}
.font20Bold {
  font-size: 20px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.font25 {
  font-size: 25px !important;
}
.font25Bold {
  font-size: 25px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
.font26 {
  font-size: 26px !important;
}
.font26Bold {
  font-size: 26px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}

// FontWeights ----------->
.font100 {
  font-weight: 100 !important;
}
.font200 {
  font-weight: 200 !important;
}
.font300 {
  font-weight: 300 !important;
}
.font400 {
  font-weight: 400 !important;
}
.font500 {
  font-weight: 500 !important;
}
.font600 {
  font-weight: 600 !important;
}
.font700 {
  font-weight: 700 !important;
}
.font800 {
  font-weight: 800 !important;
}
.font900 {
  font-weight: 900 !important;
}

.poppinsLight {
  font-family: "Inter-Light" !important;
  font-weight: 100 !important;
}
.poppinsRegular {
  font-family: 'Inter-Regular' !important;
  font-weight: 100 !important;
}
.poppinsMedium {
  font-family: 'Inter-Regular' !important;
  font-weight: 100 !important;
}
.poppinsSemiBold {
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;

}
.poppinsBold {
  font-family: "Inter-Medium" !important;
  font-weight: 100 !important;
}
